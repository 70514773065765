<template>
  <b-overlay rounded="sm" :show="loading">
    <div class="payment-page">
      <div class="payment-container">
        <div class="banner-container">
          <div class="payment-banner">
            <div class="d-flex align-items-center">
              <b-img
                fluid
                src="@/assets/images/logo/logo.gif"
                alt="Login V2"
                style="width: 40px; height: 40px;"
                class="church-logo"
              />
              
            </div>

            <p class="text-white p-0 m-0 ml-2 font-weight-bold church-title">
              {{ getChurchTitle }}
            </p>

            <p class="font-lg text-white m-0 p-0">
              <router-link :to="{ name: 'auth-login' }" class="text-white font-weight-bold">
                Login
              </router-link> 
              |
              <router-link :to="{ name: 'auth-register' }" class="text-white font-weight-bold">
                Sign Up
              </router-link> 
            </p>
          </div>
        </div>
        
        <div class="payment-wrapper">
          <b-card class="payment-card">
            <payment-page-form @loading="state => loading = state" @changeChurchName="$event = churchName = $event" />
          </b-card>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import PaymentPageForm from '@core/components/shared/PaymentPageForm.vue';

import { BOverlay, BRow, BCol, BCard, BImg } from 'bootstrap-vue'

export default {
  components: {
    BImg,
    BRow, 
    BCol, 
    BCard,
    BOverlay, 
    PaymentPageForm
  },
  data() {
    return {
      loading: false,
      churchName: ''
    }
  },
  computed: {
    getChurchTitle() {
      let name = 'The Church Of Pentecost';

      if (this.churchName){
        name += ` - ${this.churchName}`
      }

      return name
    }
  }
}
</script>

<style lang="scss">
$neutral : #80A0AF;
$main-color: #011151;

.payment-page {
  position: relative;
  height: 100vh;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: gray;
}

.payment-container {
  margin: 5% auto 0 auto;
  width: 90%;
}

.fix-bottom {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background: white;
  height: 100px;
  max-width: 100%;
  z-index: 100;
  padding-left: 20px;
  padding-right: 20px;
}

.banner-container {
  position: sticky;
  top: 0px;
  width: 100%;
  z-index: 100;
  height: 60px;
}

.payment-banner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #145DA0;
  height: 60px;
  padding-left: 10px;
  padding-right: 10px;
}

.payment-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  .payment-card {
    width: 100%;
  }
}

.church-title {
  font-size: 12px;
  width: 50%;
  text-align: center;
}

.church-logo {
  width: 40px !important;
  height: 40px !important;
}

@media (min-width: 768px) {
  .payment-container {
    width: 50%;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  .church-title {
    width: 80%;
    font-size: 1.5rem;
  }
  .church-logo {
    width: 55px !important;
    height: 55px !important;
  }
}


.custom-input {
  position: relative;
  display: block;
  color:  #80A0AF !important;

  .leading{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    padding-left: 12px;
    padding-right: 0.5rem;
    display: flex;
    align-items: center;
  }

  .trailing {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    padding-right: 12px;
    padding-left: 0.5rem;
    display: flex;
    align-items: center;
  }

  .otp {
    letter-spacing: 2rem;
    font-weight: bold;
    text-align: center !important;
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
    padding-left: 3.5rem !important;
  }


  input, textarea, select {
    border: 1px solid $neutral;
    border-radius: 5px;
    width: 100%;
    top: 50%;
    bottom: 50%;
    min-width: 50px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 2.75rem;
    color:  #80A0AF !important;

    &:focus {
      outline: none;
      border: 1px solid $main-color;
    }

    &::placeholder {
      color: $neutral;
      opacity: 0.3;
    }
    &:invalid {
      border-color: red;
    }
    &:required {
      border-color: red;
    }
    &:-ms-input-placeholder {
      color: $neutral;
    }
  }
}

#amount {
  border: 0px;
  border-bottom: 1px solid rgb(236, 235, 235);
  min-width: 50px;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 1rem;
  color:  #80A0AF !important;
  font-size: 40px;
  font-weight: bold;

  &::placeholder {
    color: $neutral;
    opacity: 0.3;
  }

  &:focus {
    outline: none;
    border: 0px;
    border-bottom: 1px solid rgb(236, 235, 235);
  }
}

.currency {
  color: $neutral;
  position: absolute;
  top: 5px;
  // left: -10px;
}

sup {
  font-size: 1rem;
}
</style>
